// project specific js
requirejs(['jquery'], function ($) {
  var sidebarMarginLeft;
  $("#service").click( function() {
      if ($(this).hasClass("inactive")) {
          console.log($(".sidebar-fid li.sidebar-item").css("marginLeft"));

          sidebarMarginLeft = $(".sidebar-fid li.sidebar-item").css("marginLeft");
          $(".sidebar-fid li.sidebar-item").css("display", "block");
          $(".sidebar-fid li.sidebar-item").css("marginLeft", 0);
          $("#service img").attr("src", "/content/dam/f0684-0/individualisierung/internetneu_1/19_2/images/close.svg");
          $(this).removeClass("inactive");
      } else {
          $(".sidebar-fid li.sidebar-item").css("display", "none");
          $(".sidebar-fid li.sidebar-item").css("marginLeft", sidebarMarginLeft);
          $("#service img").attr("src", "/content/dam/f0684-0/individualisierung/internetneu_1/19_2/images/kompass.svg");
          $(this).addClass("inactive");
      }
  });
  $(".sidebar-item a").click( function() {
      if ($(this).parent().find(".addInfo").css("display") == "none") {
          $(".addInfo").css("display", "none");
          $(this).parent().find(".addInfo").css("display", "block");
      } else $(this).parent().find(".addInfo").css("display", "none");
  });
});